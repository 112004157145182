import QRCodeStyling from 'qr-code-styling';
import {jsPDF} from 'jspdf';

export async function generateQRCode(data: string, originText: string, notificationText: string, image?: Blob): Promise<jsPDF> {
    let imageUrl = require('@/assets/logo_new.svg'); // default
    if (image && image.size) {
        // Create a temporary URL for the Blob
        const blobObject = new Blob([image]);
        imageUrl = URL.createObjectURL(blobObject);
    }

    // qr code generation
    const qrCode = new QRCodeStyling({
        data,
        image: imageUrl,
        width: 800,
        height: 800,
        margin: 50,
        dotsOptions: {
            color: '#000',
            type: 'square',
        },
        imageOptions: {
            margin: 2,
            imageSize: 0.2,
        },
    });

    // size of the image should be about the size of the document
    const imageSize = 18;

    const imgData = 'data:image/jpeg;base64,' + Buffer.from(await (await qrCode.getRawData('png') as Blob).arrayBuffer()).toString('base64');

    // centering the image
    const doc = new jsPDF({format: 'a4', orientation: 'p', unit: 'cm'});
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;


    doc.setFont('roboto', 'bold');

    doc.setFontSize(24);

    const jobOriginTextWidth = doc.getTextWidth(originText);


    doc.addImage(imgData, 'png', (pageWidth - imageSize) / 2, (pageHeight - imageSize) / 2, imageSize, imageSize);
    doc.text(originText, (pageWidth - jobOriginTextWidth) / 2, pageHeight - 5);

    doc.setFontSize(14);
    const notificationTextWidth = doc.getTextWidth(notificationText);

    doc.text(
        notificationText,
        (pageWidth - notificationTextWidth) / 2, pageHeight - 2);

    return doc;
}
