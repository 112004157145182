
































































































/**
 * Represents the master data of a company
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Company from '@/models/Company';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import CompanyManageComponent from '@/components/company/CompanyManage.component.vue';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';
import {namespace} from 'vuex-class';
import {generateQRCode} from '@/interfaces/QRHelper';

const CompanyStore = namespace('company');


@Component({
  components: {BaseDataCellComponent, SideCardComponent, CompanyManageComponent},
})
export default class CompanyMasterDataComponent extends Vue {
  @CompanyStore.Action('loadCompanyLogoAction')
  private loadCompanyLogoAction!: (companyId: string) => Promise<Blob>;

  @Prop({default: undefined})
  public readonly company: Company | undefined;

  private async downloadQrCode() {
    const blob = await this.loadCompanyLogoAction(this.$route.params.companyId);

    const notificationText = this.$t('CLEANTIME_OVERVIEW.QR_CODE_NOTIFICATION', {companyName: this.company!.name}).toString();
    const originText = this.$t('TIMETRACKING.FILTER.WORK_SESSION_TYPE_VALUES.SPECIAL_JOB').toString();
    const qrCodeData = this.company!.id;
    const doc = await generateQRCode(qrCodeData, originText, notificationText, blob);

    doc.save(`QR_${originText}`);

  }
}
